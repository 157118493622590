import { memo } from 'react';

import { Navigate } from 'react-router-dom';
import { selectIsLoggedIn } from 'redux/auth/selectors';
import { useAppSelector } from 'redux/hooks';

type Props = {
  component: JSX.Element;
  redirectTo?: string;
};

export const PrivateRoute = memo<Props>(
  ({ component: Component, redirectTo = '/login' }) => {
    const isLoggedIn = useAppSelector(selectIsLoggedIn);

    return isLoggedIn ? Component : <Navigate to={redirectTo} />;
  }
);
