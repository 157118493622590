import { lazy, memo, Suspense } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as Sentry from '@sentry/react';
import {
  ErrorBoundaryLayout,
  RouterErrorBoundary,
} from 'components/ErrorBoundary/ErrorBoundary';
import { PrivateRoute } from 'components/PrivateRoute';
import { RestrictedRoute } from 'components/RestrictedRoute';
import { VersionController } from 'components/Snackbar/VersionController';
import { Theme } from 'components/Theme/ThemeWrapper';
import { REDIRECT_PATH } from 'hooks/useAuthPopup';
import { RunDetails } from 'pages/RunDetails';
import { ROUTES } from 'platformsConstants';
import { isMobile } from 'react-device-detect';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import 'i18n/config';

const EmptyStateTemplates = lazy(() => import('pages/EmptyStateTemplates'));
const ExpiredTokenPage = lazy(() => import('pages/ExpiredTokenPage'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const Invitation = lazy(() => import('pages/Invitation'));
const MyProfile = lazy(() => import('pages/MyProfile'));
const Organization = lazy(() => import('pages/Organization'));
const PasswordUpdated = lazy(() => import('pages/PasswordUpdated'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const TrackingTemplates = lazy(() => import('pages/TrackingTemplates'));
const SharedLayout = lazy(() => import('components/SharedLayout/SharedLayout'));
const SharedAdAccountsLayout = lazy(
  () => import('components/SharedLayout/SharedAdAccountsLayout')
);
const SharedTemplatesLayout = lazy(
  () => import('components/SharedLayout/SharedTemplatesLayout')
);
const LogIn = lazy(() => import('pages/LogIn'));
const SignUp = lazy(() => import('pages/SignUp'));
const EmptyState = lazy(() => import('pages/EmptyState'));
const Connections = lazy(() => import('pages/Connections'));
const ChooseAccount = lazy(() => import('pages/ChooseAccount'));
const RedirectTarget = lazy(() => import('pages/RedirectTarget'));
const MobileViewScreen = lazy(() => import('pages/MobileViewScreen'));
const NotFoundPage = lazy(() => import('pages/NotFoundPage'));
const AdAccounts = lazy(() => import('pages/AdAccounts'));
const EditTemplate = lazy(() => import('pages/EditTemplate'));
const Details = lazy(() => import('pages/Details'));
const UsersTable = lazy(() => import('pages/UsersTable'));
const Payments = lazy(() => import('pages/Payments'));
const RunHistory = lazy(() => import('pages/RunHistory'));
const SharedRunHistoryLayout = lazy(
  () => import('components/SharedLayout/SharedRunHistoryLayout')
);
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<ErrorBoundaryLayout />}
      errorElement={<RouterErrorBoundary />}
    >
      <Route
        path="/login"
        element={<RestrictedRoute component={<LogIn />} />}
      />
      <Route
        path="/signup"
        element={<RestrictedRoute component={<SignUp />} />}
      />
      <Route path="/" element={<PrivateRoute component={<SharedLayout />} />}>
        <Route
          path={ROUTES.HOME}
          element={<PrivateRoute component={<SharedAdAccountsLayout />} />}
        >
          <Route index element={<PrivateRoute component={<AdAccounts />} />} />
          <Route
            path="empty-state"
            element={<PrivateRoute component={<EmptyState />} />}
          />
          <Route
            path="connect/:platform"
            element={<PrivateRoute component={<Connections />} />}
          />
          <Route
            path="choose-account"
            element={<PrivateRoute component={<ChooseAccount />} />}
          />
        </Route>

        <Route
          path="url-templates"
          element={<PrivateRoute component={<SharedTemplatesLayout />} />}
        >
          <Route
            index
            element={<PrivateRoute component={<TrackingTemplates />} />}
          />
          <Route
            path="empty-state"
            element={<PrivateRoute component={<EmptyStateTemplates />} />}
          />
          <Route
            path="edit-template/:platform/:id"
            element={<PrivateRoute component={<EditTemplate />} />}
          />
        </Route>

        <Route
          path="run-history"
          element={<PrivateRoute component={<SharedRunHistoryLayout />} />}
        >
          <Route index element={<PrivateRoute component={<RunHistory />} />} />
          <Route
            path=":adAccountId"
            element={<PrivateRoute component={<RunHistory />} />}
          />
          <Route
            path=":adAccountId/details/:platform"
            element={<PrivateRoute component={<RunDetails />} />}
          />
        </Route>

        <Route
          path="organization"
          element={<PrivateRoute component={<Organization />} />}
        >
          <Route
            path="details"
            element={<PrivateRoute component={<Details />} />}
          />
          <Route
            path="users"
            element={<PrivateRoute component={<UsersTable />} />}
          />
          <Route
            path="billing"
            element={<PrivateRoute component={<Payments />} />}
          />
        </Route>

        <Route
          path="my-profile"
          element={<PrivateRoute component={<MyProfile />} />}
        />
      </Route>

      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/expired-token" element={<ExpiredTokenPage />} />
      <Route path="/password-updated" element={<PasswordUpdated />} />
      <Route path="/handle-invitation" element={<Invitation />} />
      <Route path={REDIRECT_PATH} element={<RedirectTarget />} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  )
);

export const App = memo(() => {
  if (isMobile) {
    return (
      <Suspense fallback={null}>
        <MobileViewScreen />
      </Suspense>
    );
  }

  return (
    <>
      <Theme>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <VersionController />
          <RouterProvider router={router} />
        </LocalizationProvider>
      </Theme>
    </>
  );
});
