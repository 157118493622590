import { forwardRef, memo, useImperativeHandle, useState } from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Slide, { SlideProps } from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CloseIcon from 'assets/svg/closeSnackbar.svg?react';
import ErrorIcon from 'assets/svg/error_alert.svg?react';
import { useTranslation } from 'react-i18next';

import { StyledMuiAlert } from './StyledMuiAlert';

export type NewVersionSnackbarHandle = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type NewVersionSnackbarProps = {
  onRefresh: () => void;
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

export const NewVersionSnackbar = memo(
  forwardRef<NewVersionSnackbarHandle, NewVersionSnackbarProps>(
    ({ onRefresh }, ref) => {
      const [open, setOpen] = useState(false);

      const { t } = useTranslation();

      useImperativeHandle(ref, () => ({
        setOpen,
      }));

      const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
      ) => {
        if (reason === 'clickaway') {
          return;
        }

        setOpen(false);
      };

      return (
        <StyledSnackbar
          TransitionComponent={SlideTransition}
          open={open}
          transitionDuration={{ enter: 300, exit: 50 }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <StyledMuiAlert
            sx={{
              width: 585,
              height: 50,
              paddingRight: '7px',
              '& .MuiAlert-message': {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
            }}
            elevation={0}
            icon={<ErrorIcon />}
            variant="filled"
            severity="error"
            color="info"
            action={
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            }
          >
            <Typography component="span">
              {t('snackBars.new_version')}
            </Typography>
            <StyledButton variant="outlined" onClick={onRefresh}>
              {t('buttons.refresh')}
            </StyledButton>
          </StyledMuiAlert>
        </StyledSnackbar>
      );
    }
  )
);

const StyledSnackbar = styled(Snackbar)(() => ({
  zIndex: 19,
  '@media (min-width: 600px)': {
    top: 16,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '10px 0',
  width: 140,
  height: 40,
  margin: '5px 20px',
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
  boxShadow: 'none',
}));
